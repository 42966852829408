import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { Notification } from '~/src/components/Typography';
import { Link } from '~/src/components/Link';
import schema from '~/src/pages/guides/ld-json-schemas/watermarks.json';
export const frontmatter = {
  title: 'How to add watermarks to your images on the fly',
  description: 'Uploadcare provides an extensive image transformation toolkit. Use the Smart Resize operation from the Uploadcare URL API to reuse the same images on your website for multiple containers and purposes.',
  header: 'How to add watermarks to your images on the fly',
  schema
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#prepare-your-tools"
        }}>{`Prepare your tools`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-super-basic-way"
        }}>{`The super basic way`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#more-variability-set-the-dimensions-position-and-opacity"
        }}>{`More variability: set the dimensions, position and opacity`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-most-secure-merge-the-image-with-the-watermark"
        }}>{`The most secure: merge the image with the watermark`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#bonus"
        }}>{`Bonus`}</a></li>
    </ul>
    <p>{`A watermark is an image, a text snippet, or a logo that is placed over another image in order to make it hard to copy, or use the image without permission. There are a couple of ways to add a watermarkto your image — with or without using Uploadcare.`}</p>
    <p>{`Here is an example of a watermarked image:`}</p>
    <img {...{
      "src": "https://ucarecdn.com/22240276-2f06-41f8-9411-755c8ce926ed/-/overlay/efd02791-7511-42e9-850d-3b3d07f110ae/35px35p/10p,10p/40p/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/35px35p/70p,5p/35p/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/35px35p/15p,70p/55p/-/overlay/efd02791-7511-42e9-850d-3b3d07f110ae/35px35p/80p,90p/50p/-/preview/1200x800/-/quality/lighter/pineapple.jpg",
      "alt": "An image of a pineapple with multiple watermarks"
    }}></img>
    <p>{`You can add watermarks by modifying the URLs of your images.`}</p>
    <h2 {...{
      "id": "prepare-your-tools"
    }}><a parentName="h2" {...{
        "href": "#prepare-your-tools"
      }}>{`Prepare your tools`}</a></h2>
    <p>{`Adding a watermark will only require an `}<Link to="https://uploadcare.com/docs/transformations/image/overlay/#overlay" target="_blank" mdxType="Link">{`overlay`}</Link>{` operation in the URLs of the images that you are already using in your app. While this operation could be applied more broadly, it’s perfect for this task.`}</p>
    <p>{`The workflow looks like this:`}</p>
    <ol>
      <li parentName="ol">{`Upload the image to use as a watermark to `}<Link to="https://app.uploadcare.com/" target="_blank" mdxType="Link">{`Uploadcare`}</Link></li>
      <li parentName="ol">{`Add the image UUID in an overlay operation to URLs of your images: `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`-/overlay/:uuid/`}</code></li>
      <li parentName="ol">{`(optional) Merge the image with the watermark`}</li>
    </ol>
    <p>{`This way, you will deliver properly watermarked images to your users, while storing the base image separately.`}</p>
    <p>{`We’ll use this image as a basis for examples below:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`/22240276-2f06-41f8-9411-755c8ce926ed/-/preview/1200x800/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/22240276-2f06-41f8-9411-755c8ce926ed/-/preview/1200x800/",
      "alt": "A base image of a pineapple"
    }}></img>
    <Link to="https://app.uploadcare.com/projects/-/files/" target="_blank" mdxType="Link">Upload</Link> an image that will be used as a watermark to Uploadcare. We used this one:
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`/b18b5179-b9f6-4fdc-9920-5539f938fc44/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/b18b5179-b9f6-4fdc-9920-5539f938fc44/",
      "alt": "Small image displaying text: Wow!"
    }}></img>
    <p>{`In order to apply a watermark to an image, you only need to construct a proper URL, and the pineapple will get its “Wow!”`}</p>
    <p>{`You’ll need some Uploadcare basics:`}</p>
    <ul>
      <li parentName="ul">{`The UUID is a unique identifier of an image on Uploadcare CDN`}</li>
      <li parentName="ul">
        <Link to="https://uploadcare.com/docs/transformations/image/" target="_blank" mdxType="Link">Image processing</Link> operations are chainable URL directives that transform the images on the fly
      </li>
    </ul>
    <h2 {...{
      "id": "the-super-basic-way"
    }}><a parentName="h2" {...{
        "href": "#the-super-basic-way"
      }}>{`The super basic way`}</a></h2>
    <p>{`Just add an overlay operation with the overlay image UUID to the original image URL. This operation will add an opaque overlay to the top left corner of the pineapple picture:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/22240276-2f06-41f8-9411-755c8ce926ed/-/preview/1200x800/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/pineapple0.jpg",
      "alt": "Image of a pineapple with an opaque watermark in the top left corner"
    }}></img>
    <h2 {...{
      "id": "more-variability-set-the-dimensions-position-and-opacity"
    }}><a parentName="h2" {...{
        "href": "#more-variability-set-the-dimensions-position-and-opacity"
      }}>{`More variability: set the dimensions, position and opacity`}</a></h2>
    <p>{`Add additional parameters separated by a slash. They must go exactly in this order, though not every operation is necessary:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "lang-text"
      }}><code parentName="pre" {...{
          "className": "lang-text"
        }}>{`-/overlay/
  :overlay_UUID/
  :relative_dimensions/
  :position/
  :opacity/`}</code></pre></div>
    <h3 {...{
      "id": "relative_dimensions"
    }}><a parentName="h3" {...{
        "href": "#relative_dimensions"
      }}>{`:relative_dimensions`}</a></h3>
    <p>{`This parameter scales the overlay – it supports two scaling modes: `}<em parentName="p">{`static`}</em>{` and `}<em parentName="p">{`dynamic`}</em>{`.`}</p>
    <p><em parentName="p">{`Static`}</em>{` scaling sets the size in pixels, and `}<em parentName="p">{`dynamic`}</em>{` scaling derives the size of an overlay from the dimensions of an underlying image. The aspect ratio is always preserved, so you define the maximum size along one of its axes:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/800x800/`}</code></p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/50%x50%/`}</code>{` (don’t forget to escape the `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`%`}</code>{` signs with `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`%25`}</code>{`)`}</p>
    <p>{`or`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/50px50p/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/22240276-2f06-41f8-9411-755c8ce926ed/-/preview/1200x800/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/50px50p/pineapple0.jpg",
      "alt": "An image of a pineapple with a large opaque watermark in the top left corner"
    }}></img>
    <h3 {...{
      "id": "position"
    }}><a parentName="h3" {...{
        "href": "#position"
      }}>{`:position`}</a></h3>
    <p>{`This parameter controls the positioning of an overlay on top of the original image using two coordinates separated by a comma. You can define the offset from the top left corner of the image in pixels or in percentage values:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/128,128/`}</code></p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/80p,80p/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/22240276-2f06-41f8-9411-755c8ce926ed/-/preview/1200x800/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/80p,80p/",
      "alt": "An image of a pineapple covered by an opaque watermark"
    }}></img>
    <h3 {...{
      "id": "opacity"
    }}><a parentName="h3" {...{
        "href": "#opacity"
      }}>{`:opacity`}</a></h3>
    <p>{`The opacity parameter controls just how opaque the watermark is. The parameter uses percentage values only, where 100%, or 100p, is equal to a fully opaque image. The example below has the image at 30% opacity:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/30p/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/22240276-2f06-41f8-9411-755c8ce926ed/-/preview/1200x800/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/30p/",
      "alt": "A pineapple image with a semi-transparent watermark in the top left corner"
    }}></img>
    <h3 {...{
      "id": "now-all-together"
    }}><a parentName="h3" {...{
        "href": "#now-all-together"
      }}>{`Now, all together!`}</a></h3>
    <p>{`See the result on the image below.`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/60px60p/10p,50p/30p/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/22240276-2f06-41f8-9411-755c8ce926ed/-/preview/1200x800/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/60px60p/10p,50p/30p/",
      "alt": "An image of a pineapple with a semi-transparent large watermark located near the center of the page"
    }}></img>
    <p>{`You can layer as many watermarks over an image as you like. Here’s the image of a pineapple with four watermarks that all have different property values:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/overlay/efd02791-7511-42e9-850d-3b3d07f110ae/35px35p/10p,10p/40p/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/35px35p/70p,5p/35p/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/35px35p/15p,70p/55p/-/overlay/efd02791-7511-42e9-850d-3b3d07f110ae/35px35p/80p,90p/50p/-/preview/1200x800/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/22240276-2f06-41f8-9411-755c8ce926ed/-/preview/1200x800/-/overlay/efd02791-7511-42e9-850d-3b3d07f110ae/35px35p/10p,10p/40p/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/35px35p/70p,5p/35p/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/35px35p/15p,70p/55p/-/overlay/efd02791-7511-42e9-850d-3b3d07f110ae/35px35p/80p,90p/50p/-/preview/1200x800/",
      "alt": "An image of a pineapple with multiple watermarks"
    }}></img>
    <h2 {...{
      "id": "the-most-secure-merge-the-image-with-the-watermark"
    }}><a parentName="h2" {...{
        "href": "#the-most-secure-merge-the-image-with-the-watermark"
      }}>{`The most secure: merge the image with the watermark`}</a></h2>
    <p>{`Applying a watermark is as simple as extending an image URL. Thus, by just looking at a URL you made, a user may guess how to edit it to get rid of watermarks.`}</p>
    <h3 {...{
      "id": "using-upload-api-upload-from-url"
    }}><a parentName="h3" {...{
        "href": "#using-upload-api-upload-from-url"
      }}>{`Using Upload API (upload from URL)`}</a></h3>
    <p>{`This is an easier way, in our opinion. You’ll be using the `}<Link to="https://uploadcare.com/api-refs/upload-api/#operation/fromURLUpload" target="_blank" mdxType="Link"><code parentName="p" {...{
          "className": "lang-text"
        }}>{`/from_url/`}</code>{` endpoint`}</Link>{` to upload a processed image as a new file:`}</p>
    <ul>
      <li parentName="ul">{`Upload the processed image as a new image`}</li>
      <li parentName="ul">{`Get a new UUID`}</li>
      <li parentName="ul">{`Substitute the UUID in your app`}</li>
    </ul>
    <h3 {...{
      "id": "using-rest-api-copy-to-your-project"
    }}><a parentName="h3" {...{
        "href": "#using-rest-api-copy-to-your-project"
      }}>{`Using REST API (copy to your project)`}</a></h3>
    <p>{`Use Uploadcare’s `}<Link to="https://uploadcare.com/api-refs/rest-api/" target="_blank" mdxType="Link">{`REST API`}</Link>{` to merge the image layers – the image and the watermark:`}</p>
    <ul>
      <li parentName="ul">{`Make a copy of the processed image`}</li>
      <li parentName="ul">{`Get a new UUID`}</li>
      <li parentName="ul">{`Substitute the UUID in your app`}</li>
    </ul>
    <p>{`You’ll be able to operate two image versions, the original, and the processed one. With these image versions you’ll be able to deliver a full-sized version with watermarks to your users, and generate thumbnails from the original.`}</p>
    <h3 {...{
      "id": "general-workflow"
    }}><a parentName="h3" {...{
        "href": "#general-workflow"
      }}>{`General workflow`}</a></h3>
    <p>{`You can complete this task in four steps.`}</p>
    <h4 {...{
      "id": "step-1-pick-an-image"
    }}><a parentName="h4" {...{
        "href": "#step-1-pick-an-image"
      }}>{`Step 1. Pick an image`}</a></h4>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`/117a213f-94f5-453c-8533-ebde3c700d3b/-/preview/640x640/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/117a213f-94f5-453c-8533-ebde3c700d3b/-/preview/1200x800/",
      "alt": "A base image of a pineapple"
    }}></img>
    <h4 {...{
      "id": "step-2-add-a-watermark"
    }}><a parentName="h4" {...{
        "href": "#step-2-add-a-watermark"
      }}>{`Step 2. Add a watermark`}</a></h4>
    <p>{`Use the UUID of the watermark image, and optionally set its dimensions, position and opacity values using parameters in a specific order (all parameters are optional):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "lang-text"
      }}><code parentName="pre" {...{
          "className": "lang-text"
        }}>{`-/overlay/
  :overlay_UUID/
  :relative_dimensions/
  :position/
  :opacity/`}</code></pre></div>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`/117a213f-94f5-453c-8533-ebde3c700d3b/-/preview/640x640/-/overlay/46d27658-220b-41e5-bf35-b71cc05ebcab/30p,50p/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/117a213f-94f5-453c-8533-ebde3c700d3b/-/preview/1200x800/-/overlay/46d27658-220b-41e5-bf35-b71cc05ebcab/30p,50p/",
      "alt": "An image of a pineapple with an opaque watermark close to the center of the page"
    }}></img>
    <p>{`This image has been resized to 640*640 pixels, with an opaque watermark applied that is offset by 30% and 50% from the top left.`}</p>
    <h4 {...{
      "id": "step-3-upload-or-copy-the-processed-image"
    }}><a parentName="h4" {...{
        "href": "#step-3-upload-or-copy-the-processed-image"
      }}>{`Step 3. Upload or copy the processed image`}</a></h4>
    <h5 {...{
      "id": "upload-upload-api"
    }}><a parentName="h5" {...{
        "href": "#upload-upload-api"
      }}>{`Upload: Upload API`}</a></h5>
    <p>{`Make a request to `}<Link to="https://upload.uploadcare.com/from_url/" target="_blank" mdxType="Link"><a parentName="p" {...{
          "href": "https://upload.uploadcare.com/from_url/"
        }}>{`https://upload.uploadcare.com/from_url/`}</a></Link>{`.`}</p>
    <p>{`You will require only a Public key from the `}<Link to="https://app.uploadcare.com/projects/-/api-keys/" target="_blank" mdxType="Link">{`Uploadcare Dashboard`}</Link>{` in order to authenticate the request. Here is an example of the request object:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "lang-text"
      }}><code parentName="pre" {...{
          "className": "lang-text"
        }}>{`{
  "pub_key": "demopublickey",
  "source_url": "https://ucarecdn.com/117a213f-94f5-453c-8533-ebde3c700d3b/-/preview/640x640/-/overlay/46d27658-220b-41e5-bf35-b71cc05ebcab/30p,50p/",
  "store": "1"
}`}</code></pre></div>
    <p>{`Make sure you use `}<code parentName="p" {...{
        "className": "lang-text"
      }}>{`"store": "1"`}</code>{` setting if you wish to to store this file permanently.`}</p>
    <h5 {...{
      "id": "copy-rest-api"
    }}><a parentName="h5" {...{
        "href": "#copy-rest-api"
      }}>{`Copy: REST API`}</a></h5>
    <p>{`Copy operations are usually performed using an `}<Link to="https://uploadcare.com/docs/integrations/#clients" target="_blank" mdxType="Link">{`Uploadcare library`}</Link>{` for your preferred language.`}</p>
    <Notification mdxType="Notification">
  We recommend you use REST API v0.6 or higher.
    </Notification>
    <p>{`Make a request to `}<Link to="https://api.uploadcare.com/files/local_copy/" target="_blank" mdxType="Link"><a parentName="p" {...{
          "href": "https://api.uploadcare.com/files/local_copy/"
        }}>{`https://api.uploadcare.com/files/local_copy/`}</a></Link>{`.`}</p>
    <p>{`You will require both a Public key, and a Secret key from the `}<Link to="https://app.uploadcare.com/projects/-/api-keys/" target="_blank" mdxType="Link">{`Uploadcare Dashboard`}</Link>{` in order to `}<Link to="https://uploadcare.com/api-refs/rest-api/v0.7.0/#section/Authentication" target="_blank" mdxType="Link">{`authenticate`}</Link>{` the request:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "lang-text"
      }}><code parentName="pre" {...{
          "className": "lang-text"
        }}>{`bash $ curl -X POST
-H "Authorization: Uploadcare.Simple public_key:private_key"
-d "source=117a213f-94f5-453c-8533-ebde3c700d3b/-/preview/640x640/-/overlay/46d27658-220b-41e5-bf35-b71cc05ebcab/30p,50p/"
-d "store=true"
"https://api.uploadcare.com/files/local_copy/"`}</code></pre></div>
    <h4 {...{
      "id": "step-4-update-the-image-uuid-in-your-app"
    }}><a parentName="h4" {...{
        "href": "#step-4-update-the-image-uuid-in-your-app"
      }}>{`Step 4. Update the image UUID in your app`}</a></h4>
    <p>{`One of the requests above has uploaded, or copied the processed image to your project as a new merged image. The file has a new UUID, and now you can you can use a direct link to it, without any operations, to deliver the image to your users:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`/72659dd2-19d8-460d-961a-329424f2ba8a/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/02fa2c63-93ac-4a62-8825-38989f9004e1/watermarked.jpeg",
      "alt": "An image of a pineapple with a watermark baked into it"
    }}></img>
    <h2 {...{
      "id": "bonus"
    }}><a parentName="h2" {...{
        "href": "#bonus"
      }}>{`Bonus`}</a></h2>
    <h3 {...{
      "id": "order"
    }}><a parentName="h3" {...{
        "href": "#order"
      }}>{`Order.`}</a></h3>
    <p>{`The overlay operation is always performed last. Take a look at this example, where the image is also blurred:`}</p>
    <p><code parentName="p" {...{
        "className": "lang-text"
      }}>{`-/overlay/:uuid/60px60p/20p,80p/80p/-/blur/70/`}</code></p>
    <img {...{
      "src": "https://ucarecdn.com/22240276-2f06-41f8-9411-755c8ce926ed/-/overlay/b18b5179-b9f6-4fdc-9920-5539f938fc44/60px60p/20p,80p/80p/-/preview/1200x800/-/blur/70/pineapple7.jpg",
      "alt": "A blurred image of a pineapple with a sharp watermark on it"
    }}></img>
    <p>{`This sequence of operations places the watermark on the image only after it has been blurred.`}</p>
    <h3 {...{
      "id": "benchmark"
    }}><a parentName="h3" {...{
        "href": "#benchmark"
      }}>{`Benchmark.`}</a></h3>
    <p>{`The tests have shown that it takes about 20 ms to add an overlay to a small (`}<Link to="https://ucarecdn.com/773696b7-d107-4a3d-b3d1-64c25dcbf27a/-/preview/500x500/" target="_blank" mdxType="Link">{`0.2 MP`}</Link>{`) image, and only 90 ms to a mid-sized one (`}<Link to="https://ucarecdn.com/773696b7-d107-4a3d-b3d1-64c25dcbf27a/-/preview/" target="_blank" mdxType="Link">{`3 MP`}</Link>{`) — a swell result, but our CDN also gets you a further boost in loading time.`}</p>
    <p>{`If you have questions, be sure to contact our friendly Support engineers at `}<Link to="mailto:help@uploadcare.com" target="_blank" mdxType="Link"><a parentName="p" {...{
          "href": "mailto:help@uploadcare.com"
        }}>{`help@uploadcare.com`}</a></Link>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      